@import url(https://fonts.googleapis.com/css2?family=Dosis:wght@700&family=Raleway:wght@800&family=Roboto:wght@500;700&display=swap);
* {
  margin: 0;
  padding: 0;
}

.impulse-image {
  border: 1px solid #e61419;
  border-radius: 50%;
  position: relative;
}

.impulse-image .image-container {
  width: 350px;
  height: 350px;
  border: 1px solid #ff0007;
  /* box-shadow: 0px 0px 29px #b70308; */
  border-radius: 50%;
}

.impulse-image .image-container img {
  height: 75px;
  width: 75px;
  object-fit: contain;
}

.impulse-image .image-container .red-eye-circle {
  height: 120px;
  width: 120px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  box-shadow: 0px 0px 29px #b70308;
}

.impulse-image .circle {
  height: 50px;
  width: 50px;
}

.impulse-image .circle p {
  color: #ffffff;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 0;
}

.impulse-image .circle {
  position: absolute;
  height: 70px;
  width: 70px;
  /* border: 3px solid #ffffff; */
  background-color: #000000;
  border-radius: 50%;
  box-shadow: 0px 0px 29px #b70308;
}

.impulse-image .red-pill {
  background: #cd181d;
  color: #fff;
  padding: 6px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 16px;
  border: 1px solid #b40307;
  box-shadow: 0px 0px 31px rgba(0, 0, 0, 0.17);
}

.impulse-image .circle.circle1 {
  left: -85px;
  top: 25px;
  height: 190px;
  width: 190px;
  background: url(/static/media/circle1.b0eecb50.jpg) center/cover no-repeat;
  border: 1px solid #b40307;
}

.impulse-image .circle.circle1 .red-pill {
  position: absolute;
  top: 30px;
  left: -30px;
}

.impulse-image .circle.circle2 {
  left: 115px;
  top: -45px;
  height: 120px;
  width: 120px;
  background: url(/static/media/circle2.b72c6877.jpg) center/cover no-repeat;
}

.impulse-image .circle.circle2 .red-pill {
  position: absolute;
  top: 20px;
  right: -40px;
}

.impulse-image .circle.circle3 {
  right: -100px;
  top: calc(50% - 170px);
  height: 191px;
  width: 191px;
  background: url(/static/media/circle3.930ce897.jpg) center/cover no-repeat;
}

.impulse-image .circle.circle3 .red-pill {
  position: absolute;
  top: 30px;
  right: -20px;
}

.impulse-image .circle.circle4 {
  right: -75px;
  bottom: -85px;
  height: 220px;
  width: 220px;
  background: url(/static/media/circle4.5d9bf403.jpg) center/cover no-repeat;
}

.impulse-image .circle.circle4 .red-pill {
  position: absolute;
  top: 30px;
  right: -20px;
}

.impulse-image .circle.circle5 {
  left: 25px;
  bottom: -50px;
  height: 150px;
  width: 150px;
  background: url(/static/media/circle5.86b7fbd3.jpg) center/cover no-repeat;
}

.impulse-image .circle.circle5 .red-pill {
  position: absolute;
  top: 30px;
  left: -20px;
}

.flex-css {
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeline-container {
  background-color: #b40207;
  padding-bottom: 100px !important;
}

.timeline-container .timeline-heading {
  margin-bottom: 100px !important;
}

.timeline-container .timeline-heading h1 {
  font-size: 25px;
  font-weight: bold;
  color: #f1f1f1;
}

.timeline-container .timeline-heading {
  font-size: 16px;
  color: #f1f1f1;
}

.timeline {
  position: relative;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto !important;
}

.timeline::after {
  content: "";
  position: absolute;
  width: 2px;
  background: #ff7377;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -1px !important;
}

.timeline .timeline-point {
  position: absolute;
  left: calc(50% - 48px);
  height: 96px;
  width: 96px;
  border-radius: 50%;
  z-index: 1;
  box-shadow: 0px 0px 37px #850004;
}

.timeline .timeline-point.first-point {
  top: -40px;
}

.timeline .timeline-point.second-point {
  top: 21%;
}

.timeline .timeline-point.third-point {
  top: 48%;
}

.timeline .timeline-point.end-circle {
  bottom: -40px;
  border: none;
  background: rgba(255, 255, 255, 0.28);
}

.timeline .timeline-point.end-circle .circle {
  border-radius: 50%;
}

.timeline .timeline-point.end-circle .circle1 {
  background: rgba(255, 255, 255, 0.26);
  height: 60px;
  width: 60px;
}

.timeline .timeline-point.end-circle .circle2 {
  background: rgba(255, 255, 255, 0.25);
  height: 40px;
  width: 40px;
}

.timeline .timeline-point.end-circle .circle3 {
  background: #ffffff;
  height: 20px;
  width: 20px;
}

.timeline .timeline-point p {
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 0;
}

.timeline .container-box {
  padding: 15px 115px !important;
  position: relative;
  background: inherit;
  width: 50%;
}

.timeline .container-box.event-starts {
  margin-top: 150px !important;
}

.timeline .container-box.left {
  left: 0;
}

.timeline .container-box.right {
  left: 50%;
}

.timeline .container-box::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  top: calc(50% - 5px);
  right: -5px;
  background: #ff7377;
  border-radius: 10px;
  z-index: 1;
}

.timeline .container-box.right::after {
  left: -5px;
}

.timeline .container-box::before {
  content: "";
  position: absolute;
  width: 200px;
  height: 2px;
  top: calc(50% - 1px);
  right: 5px;
  border: 1px dashed #ff7377;
}

.timeline .container-box.right::before {
  left: 5px;
}

.timeline .container-box .content {
  padding: 20px !important;
  border-radius: 10px;
  position: relative;
}

.timeline .container-box .content h2 {
  margin: 0 0 10px 0 !important;
  font-size: 22px;
  font-weight: bold;
  color: #ffffff;
}

.timeline .container-box .content p {
  margin: 0 !important;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
}

.vector-parent {
  position: relative;
  overflow: hidden;
}

.nft-image .image-container {
  width: 600px;
  height: 380px;
  position: relative;
}

.nft-image .image-container .laptop-ntf {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.sk-dot-background {
  background: url(/static/media/Vector.32c2d127.png) center/contain no-repeat;
}

/* footer box  */
.footer-box {
  background-color: #121212;
  background: url(/static/media/Vector.32c2d127.png) center/contain no-repeat;
}

.footer-box .first-footer .policy .logo-container img {
  height: 50px;
}

.footer-box .first-footer .policy .policy-links a {
  color: #f1f1f1;
  text-decoration: none;
  font-size: 14px;
  margin-right: 15px !important;
}

.footer-box .first-footer .extra-links {
  align-items: flex-start;
}

.footer-box .first-footer .extra-links .link-box.link2 {
  margin: 0 60px !important;
}

.footer-box .first-footer .extra-links a {
  color: #f1f1f1;
  text-decoration: none;
  font-size: 14px;
  margin-bottom: 10px !important;
}

.footer-box .second-footer .copyright p {
  color: #f1f1f1;
  font-size: 14px;
}

.footer-box .second-footer .social-links a {
  background: #000000;
  border: 1px solid #ffffff;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin: 0 10px !important;
  text-decoration: none;
}

.footer-box .second-footer .social-links a i {
  color: #f1f1f1;
  font-size: 30px;
}

.flex-row-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-row-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex-column-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.live-icon {
  color: #848484;
}

.input-group-text {
  background: #4e4e4e !important;
  border: 1px solid #848484 !important;
}

#search-filter .form-select,
#search-filter .form-control,
#search-filter .calend {
  background: #161616 !important;
  border: 1px solid #848484 !important;
  color: #848484;
}

.live-icon-active {
  color: #fff;
}

/* profile  */
.profile-box .profile-container {
  background-color: #222222;
  border-radius: 8px;
}

.profile-box .profile-container .account-info .image-container {
  height: 100px;
  width: 100px;
}

.profile-box .profile-container .account-info .image-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.profile-box .profile-container .account-info .details-container .name p {
  color: #f1f1f1;
  font-weight: bold;
  font-size: 1.4em;
}

.profile-box .profile-container .account-info .details-container .name a {
  color: #f1f1f1;
  text-decoration: none;
}

.profile-box .profile-container .account-info .details-container .idcode p {
  color: #f1f1f1;
  font-weight: normal;
  font-size: 1em;
  padding-right: 10px;
}

.profile-box .profile-container .account-info .details-container .idcode a {
  color: #f1f1f1;
  text-decoration: none;
}

.profile-box .profile-container .balance-info .balance-details {
  border: 1px solid #f1f1f1;
  border-radius: 6px;
  /* width: 300px; */
  height: 100px;
}

.profile-box .profile-container .balance-info .balance-details .heading p {
  font-size: 14px;
  font-weight: bold;
  color: #f1f1f1;
}

.profile-box .profile-container .balance-info .balance-details .heading p img {
  height: 20px;
}

/* .profile-box .profile-container .balance-info .bio-desc {
  width: 250px;
} */

.profile-box .profile-container .balance-info .bio-desc .bio-heading h1 {
  color: #f1f1f1;
  font-size: 16px;
  font-weight: bold;
}

.profile-box .profile-container .balance-info .bio-desc .bio-heading i {
  color: #f1f1f1;
}

.profile-box .profile-container .balance-info .bio-desc p {
  color: #f1f1f1;
  font-size: 1em;
}

.profile-box .profile-container .my-orders-btn button {
  border: none;
  outline: none;
  background-color: #ff454a;
  color: #f1f1f1;
  font-weight: bold;
  font-size: 12px;
  padding: 7px 20px !important;
  border-radius: 4px;
}

.profile-box .profile-container .broadcast-history .heading h1 {
  color: #f1f1f1;
  font-weight: bold;
  font-size: 16px;
}

.profile-box .profile-container .broadcast-history .heading p span {
  color: #f1f1f1;
  font-weight: normal;
  font-size: 11px;
  margin-right: 15px !important;
}

.profile-box .profile-container .broadcast-history .content .tabs {
  background-color: #c4c4c4;
  border-radius: 13px;
  width: 190px;
  position: relative;
}

.profile-box .profile-container .broadcast-history .content .tabs .tab-wrapper {
  background-color: #f1f1f1;
  border-radius: 13px;
  height: 100%;
  width: 50%;
  position: absolute;
  top: 0;
}

.profile-box
  .profile-container
  .broadcast-history
  .content
  .tabs
  .tab-wrapper.left {
  left: 0;
}

.profile-box
  .profile-container
  .broadcast-history
  .content
  .tabs
  .tab-wrapper.right {
  right: 0;
}

.profile-box .profile-container .broadcast-history .content .tabs span {
  color: #121212;
  font-weight: bold;
  font-size: 12px;
  z-index: 1;
  cursor: pointer;
  padding: 3px 25px !important;
  width: 100%;
}

.profile-box
  .profile-container
  .broadcast-history
  .content
  .content-box.hidden {
  display: none;
}

.profile-box
  .profile-container
  .broadcast-history
  .content
  .content-box
  .list-box
  .video-container {
  width: 170px;
  height: 130px;
  border-radius: 6px;
  position: relative;
}

.profile-box
  .profile-container
  .broadcast-history
  .content
  .content-box
  .list-box
  .video-container
  img {
  /* width: 100%; */
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.profile-box
  .profile-container
  .broadcast-history
  .content
  .content-box
  .list-box
  .video-container
  .play-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
  cursor: pointer;
}

.profile-box
  .profile-container
  .broadcast-history
  .content
  .content-box
  .list-box
  .video-container
  .play-wrapper
  i {
  color: #f1f1f1;
  font-size: 35px;
}

.profile-box
  .profile-container
  .broadcast-history
  .content
  .content-box
  .list-box
  .video-info
  h1 {
  font-size: 14px;
  color: #f1f1f1;
  font-weight: bold;
}

.profile-box p {
  margin-bottom: 0;
}

.profile-box
  .profile-container
  .broadcast-history
  .content
  .content-box
  .list-box
  .video-info
  p {
  font-size: 10px;
  color: #f1f1f1;
  font-weight: normal;
}

.profile-image-container {
  position: relative;
}

.wallet-username-edit-input {
  display: none;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 14px;
  padding: 0 5px 0 5px;
  background-color: #4e4e4e;
  color: #ffffff;
  box-sizing: border-box;
  min-width: 90px;
  border-radius: 5px;
  font-weight: 600;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.profile-image-container:hover .edit-profile-image-hover {
  display: flex;
}

.edit-profile-image-hover {
  cursor: pointer;
  position: absolute;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  color: #ffffff;
  display: none;
  align-items: center;
  justify-content: center;
}

/*  */

.red_font {
  color: #e51319;
}

.red_eye_logo {
  height: 68px;
  width: 68px;
  margin-top: 72px;
  margin-bottom: 20px;
}

video.bg {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.video-wrapper {
  min-height: 95vh;
  position: relative;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  position: relative;
  color: white;
  text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.6);
  z-index: 2;
}

.video-wrapper:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1;
}

.red-btn {
  background: linear-gradient(
    270deg,
    #cc171c 0%,
    #e22c31 48.96%,
    #ff454a 106.1%
  );
  border: none !important;
}

.red-gradient-btn {
  background: linear-gradient(
    270deg,
    #cc171c 0%,
    #e22c31 48.96%,
    #ff454a 106.1%
  );
  border: none !important;
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.pre-subscribe-btn {
  background: linear-gradient(
    270deg,
    #cc171c 0%,
    #e22c31 48.96%,
    #ff454a 106.1%
  );
  border: none !important;
  font-size: 14px !important;
  font-weight: bold !important;
  padding: 10px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  margin-top: 2em;
  margin-bottom: 10em;
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.header-text-font {
  font-family: "Raleway", "Roboto", sans-serif;
  font-size: 40px;
}

.raleway-font {
  font-family: "Raleway", "Roboto", sans-serif;
}

.red-bg {
  background: linear-gradient(180deg, #db0e13 0%, #b91519 100%);
}

.navbar-nav a {
  margin-left: 10px;
}

.navbar-nav {
  text-align: center;
}

.upload-news-btn {
  background: #fff !important;
  color: #db0e13 !important;
  border: none !important;
  font-weight: bold !important;
  padding: 10px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.make-offer-btn {
  background: rgba(255, 69, 74, 1) !important;
  color: #fff !important;
  border: none !important;
  font-weight: bold !important;
  padding: 10px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  width: 100%;
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.buy-now-btn {
  /* background: #cd181d !important; */
  color: #fff !important;
  border: none !important;
  font-weight: bold !important;
  padding: 10px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  width: 100%;
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.problem-card {
  background: #313131;
  border-radius: 10px;
  color: #c4c4c4;
  position: relative;
  padding: 20px;
  padding-top: 60px;
  margin-bottom: 40px;
  min-height: 290px;
}

.card-badge {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -20px;
  left: 16px;
}

.red-hue {
  background: linear-gradient(
    90.14deg,
    rgba(202, 21, 26, 0.38) -12.83%,
    #141414 99.88%
  );
  mix-blend-mode: normal;
  border-radius: 30px;
  padding: 16px;
  margin-left: 10px;
}

.white-badge {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.white-card-badge {
  background: #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -22px;
  left: 16px;
}

.solution-card {
  background: #ca1419;
  border-radius: 10px;
  color: #fff;
  position: relative;
  padding: 20px;
  padding-top: 40px;
  margin-bottom: 40px;
  min-height: 270px;
}

.pre-sub-card {
  height: 100px;
  background: linear-gradient(258.81deg, #ca1419 -38.43%, #141414 100%);
  border-radius: 10px;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #f1f1f1;
}

.form-floating > .form-control {
  background: transparent;
  border: none !important;
  color: #fff;
  outline: none !important;
}

.form-floating > .form-control:focus {
  background: transparent;
  border: none !important;
  color: #fff;
  outline: none !important;
}

.under-line {
  border-bottom: 2px solid #fff !important;
}

.under-line:focus {
  border-bottom: 2px solid #fff !important;
}

.timeline-card {
  text-align: center;
}

.timeline-card .content {
  min-height: 370px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.timeline-card img {
  width: 200px;
  margin-bottom: 25px;
}

.header_icon_row {
  margin-bottom: 90px;
  text-align: left;
}

.header_icon_col {
  min-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 800px) {
  .header_icon_row {
    display: flex !important;
    flex-direction: column;
    grid-gap: 50px;
    align-items: center;
  }
  .header_icon_col {
    justify-content: center;
    width: 470px !important;
    min-width: 100px !important;
  }
}

@media (max-width: 986px) {
  .navbar-nav a button {
    margin-left: 0 !important;
  }

  .responsive_center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .responsive_center * {
    text-align: center;
  }

  .responsive_center_text {
    text-align: center;
  }

  .last_card {
    height: auto !important;
  }

  .response_vertical_flex {
    display: flex;
    flex-direction: column;
  }

  .pre-subscribe-btn {
    margin-bottom: 60px;
  }
}

@media (max-width: 450px) {
  .impulse-image {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
  }
  .header-text-font h1 {
    font-size: 24px;
  }

  .header p {
    width: 60%;
  }

  .responsive_flex_content {
    display: contents !important;
  }

  .repsonsive_to_block {
    display: block !important;
  }

  .red-hue {
    padding-bottom: 30px;
  }

  .problem-card {
    min-height: 0 !important;
  }

  .red-hue .header-text-font,
  #problem > .responsive_center_text {
    font-size: 48px !important;
  }

  #problem p.desc {
    text-align: center;
  }

  #problem {
    display: contents !important;
  }

  .policy {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }

  .footer-links * {
    text-align: center;
  }

  #pre * {
    text-align: center !important;
  }

  .timeline .container-box {
    width: 100%;
    padding: 0 !important;
  }

  .timeline .container-box::before,
  .timeline .container-box::after {
    display: none;
  }

  .timeline .container-box.right {
    left: 0;
  }

  .timeline-card {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    z-index: 3;
  }

  .timeline .timeline-point.second-point {
    top: 22.7%;
  }

  .timeline .timeline-point.third-point {
    top: 46.7%;
  }

  .end-circle {
    display: none;
  }

  .timeline.py-5 {
    padding-bottom: 0 !important;
  }

  .solution-card {
    min-height: 0 !important;
    height: auto !important;
  }
}

.modal-content {
  background: #121212 !important;
  color: #fff;
  border: 1px solid #fff !important;
}

.modal-body input,
.modal-body textarea {
  width: 100%;
  margin-top: 10px;
  height: 40px;
  border-radius: 10px;
  padding: 8px;
}

.modal-body textarea {
  height: 160px;
}

.react-datepicker__input-container {
  height: 100%;
}

.react-datepicker__input-container input {
  height: 100%;
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

#filter-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#time-filter {
  display: flex;
  flex-direction: row;
  border: 2px solid #4e4e4e;
  border-radius: 5px;
}

#search-filter {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.profile-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  #filter-container {
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
  }

  #time-filter {
    justify-content: center;
  }

  #search-filter {
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
    align-items: center;
  }

  .profile-info-container {
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
    justify-content: center;
  }

  .account-info {
    flex-direction: column !important;
  }
}

.preSubInfoBox {
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 5px;
}

.activeLang {
  background-color: #b40407;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 3px;
}

.inactiveLang {
  border: 1px solid #b40407;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 3px;
}

.clickable {
  cursor: pointer;
}

.special-grey {
  color: #ababab !important;
}

